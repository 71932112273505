import { WikiLayout } from "@/layouts/WikiLayout"
import { PageList } from "@/components/alluena/PageList"
import { fetchArticles } from "@/hooks/wiki/fetchArticles"
import styles from "./Wikipage.module.scss"

type WikiViewProps = {
  pages: WikiPageDescriptor[]
}

function WikiIndex({ pages }: WikiViewProps) {
  const nav = <PageList pages={pages} />

  return (
    <WikiLayout nav={nav}>
      <h1 className={`${styles["wiki-page-header"]} text-center sm:text-left pb-0`}>
        Willkommen im AllüNa! <br />
        <small className={styles["wiki-page-header--sub"]}>Dem allumfassenden, lückenhaften Nachschlagewerk.</small>
      </h1>
      <hr className="not-prose" />
    </WikiLayout>
  )
}

export async function getStaticProps() {
  const pages = await fetchArticles()

  return {
    props: {
      pages
    }
  }
}

export default WikiIndex